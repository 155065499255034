import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(arr: any[], searchTerm: string, title:string): unknown {
      if(!arr || !arr.length || !searchTerm ){
        return arr;
      }

      let filterArray = arr.filter(element =>{
        if(element[title].toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase())> -1){
          return element
        }
      });

      return filterArray;
      
  }

}
