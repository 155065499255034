import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-hyperlink-cell-renderer',
  templateUrl: './hyperlink-cell-renderer.component.html',
  styleUrls: ['./hyperlink-cell-renderer.component.scss']
})
export class HyperlinkCellRendererComponent implements ICellRendererAngularComp {

  params:any;
  urlSegment: any;
  


  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params
    this.urlSegment = this.params.urlSegment
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

}
