import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData = {
    user_id: 1,
    user_type_id: 1,
    username: "Priyanshu",
    email: "email@gmail.com",
    token: "zA8NpCMCn2mBNtQ0KZGRD3sJ4ACBPbChkIcnEweLgH7czmmqSkO4ye2gya0Qbg3AveZdxo1uKmGTWKkYQyVrsF85rqCGRVPQLtxk",
    is_active: 1,
    is_deleted: 0,
    user_type: {
      user_type_id: 1,
        user_type_name:"Admin",
        is_active: 1
    }
  }


  constructor(private http: HttpClient, private router : Router) { }


  loginUser(body:any){
    // return this.http.post(API.login,body).subscribe(
    //   (res) =>{
    //     if (res['success']==true) {
    //       this.userData = res['data'];
    //       localStorage.setItem('user', JSON.stringify(this.userData));
    //       JSON.parse(localStorage.getItem('user')!);
    //       this.router.navigate(['/dashboard']);
    //     } else {
    //       localStorage.setItem('user', 'null');
    //       JSON.parse(localStorage.getItem('user')!);
    //     }
    //   }
    // );

    localStorage.setItem('user', JSON.stringify(this.userData));
    JSON.parse(localStorage.getItem('user')!);
    this.router.navigate(['/dashboard']);
  }

  SignOut() {
      localStorage.clear();
      localStorage.removeItem('user');
      this.router.navigate(['/auth/login']);
  }
}
