import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-checkbox-cell-renderer',
  templateUrl: './checkbox-cell-renderer.component.html',
  styleUrls: ['./checkbox-cell-renderer.component.scss']
})
export class CheckboxCellRendererComponent implements ICellRendererAngularComp {
  params: any
  agInit(params: ICellRendererParams<any, any, any>): void {
    console.log(params.value)
    this.params = params;
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
  //   this.params = params;
  //  return true;
  return true
  }

}
