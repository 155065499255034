import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-action-buttons-cell-renderer',
  templateUrl: './action-buttons-cell-renderer.component.html',
  styleUrls: ['./action-buttons-cell-renderer.component.scss']
})
export class ActionButtonsCellRendererComponent implements ICellRendererAngularComp{


  constructor(private modalService: NgbModal){}

  params: any;
  cartFunction: Function;
  shoopingBasketFunction: Function;
  edit_url_segment: string;
  agInit(params: ICellRendererParams<any, any, any>): void {
   this.params = params
   console.log(params);
   this.cartFunction = this.params.cart_function
   this.edit_url_segment = this.params.edit_url_segment

  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true
  }



}
