import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  action?: boolean
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  public adminMenuCollapsed: boolean = false


  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      path: "/dashboard",
      title: "Dashboard",
      icon: "home",
      type: "link",
      bookmark: true,
    },
    {
      title: "Product",
      icon: "ecommerce",
      type: "sub",
      children: [
        { path: "/product/product", title: "Product", type: "link" },
        { path: "/product/stocks", title: "Stock", type: "link" },
      ],
    },
    {
      title: "Orders",
      icon: "button",
      type: "sub",
      children: [
        { path: "/orders/active-orders", title: "Active Orders", type: "link" },
        { path: "/orders/past-orders", title: "Past Orders", type: "link" },
        { path: "/orders/option-group", title: "Abondond Cart", type: "link" },
      ],
    },
    {
      title: "Customers",
      icon: "user",
      type: "sub",
      children: [
        { path: "/customers/customers", title: "Customers", type: "link" },
        { path: "/customers/newsletter", title: "Newsletters", type: "link" },
      ],
    },
    {
      title: "Reports",
      icon: "charts",
      type: "sub",
      children: [
        { path: "/reports/sales-report", title: "Sales Report", type: "link" },
      ],
    },
    {
      title: "UX",
      icon: "ui-kits",
      type: "sub",
      children: [
        { path: "/ux/home-banner", title: "Home Banners", type: "link" },
        { path: "/ux/menu", title: "Menu", type: "link" },
        { path: "/ux/policy-pages", title: "Policy Pages", type: "link" },
      ],
    },
    {
      title: 'Admin',
      icon: "icons",
      type:"sub",
      action: true
    }
   
  ];

  ADMINMENU: Menu[] = [
    {
      title: "Product Set Up",
      icon: "bonus-kit",
      type: "sub",
      children: [
        // { path: "/product-setup/supplier", title: "Supplier", type: "link" },
        { path: "/product-setup/category", title: "Category", type: "link" },
        // { path: "/product-setup/colours", title: "Colours", type: "link" },
        // { path: "/product-setup/tags", title: "Tags", type: "link" },
      ],
    },
    {
      title: "Currency Management",
      icon: "project",
      type: "sub",
      children: [
        // { path: "/currency-management/currency", title: "Currency", type: "link" },
        { path: "/currency-management/currency-rate", title: "Currency Rate", type: "link" },
      ],
    },
    {
      title: "Tag Management",
      icon: "calender",
      type: "sub",
      children: [
        { path: "/tag-management/tags", title: "Tags", type: "link" },
      ],
    },
    {
      title: "Color Management",
      icon: "search",
      type: "sub",
      children: [
        { path: "/color-management/color-group", title: "Color Group", type: "link" },
        { path: "/color-management/colors", title: "Colors", type: "link" },
      ],
    },
    {
      title: "Note Management",
      icon: "card-checklist",
      type: "sub",
      children: [
        { path: "/note-template-management/note", title: "Note", type: "link" },
        { path: "/note-template-management/note-template", title: "Note Template", type: "link" },
      ],
    },
    {
      title: "Suppiler Management",
      icon: "widget",
      type: "sub",
      children: [
        { path: "/supplier-management/supplier", title: "Supplier", type: "link" },
      ],
    },
    {
      title: "Discount",
      icon: "blog",
      type: "sub",
      children: [
        { path: "/discount/coupon-generate", title: "Coupon Generate", type: "link" },
        { path: "/discount/direct-discount", title: "Direct Discount", type: "link" },
      ],
    },
    {
      title: "Size Chart",
      icon: "charts",
      type: "sub",
      children: [
        { path: "/size-chart/size-chart", title: "Size Chart", type: "link" },
      ],
    },
    {
      title: "Site Set up",
      icon: "task",
      type: "sub",
      children: [
        { path: "/site-setup/options-variant", title: "Option/Variant", type: "link" },
        { path: "/site-setup/payment-gateway", title: "Payment Gateway", type: "link" },
      ],
    },
    {
      title: "Shipping Set up",
      icon: "maps",
      type: "sub",
      children: [
        // { path: "/shipping-setup/country-setup", title: "Country Set up", type: "link" },
        { path: "/shipping-setup/shipping-provider", title: "Shipping Providers", type: "link" },
        { path: "/shipping-setup/provider-zone", title: "Provider Zone", type: "link" },
        { path: "/shipping-setup/provider-rate", title: "Provider Rate", type: "link" },
      ],
    },
    {
      title: "Staff Management",
      icon: "social",
      type: "sub",
      children: [
        { path: "/staff-management/roles", title: "Roles Management", type: "link" },
        { path: "/staff-management/access", title: "Access Management", type: "link" },
      ],
    },
    {
      title: "UX Management",
      icon: "learning",
      type: "sub",
      children: [
        { path: "/ux-management/social-handles", title: "Social handles", type: "link" },
      ],
    },
    {
      title: "Log Management",
      icon: "faq",
      type: "sub",
      children: [
        { path: "/log-management/activity-log", title: "Activity Log", type: "link" },
      ],
    },
  ]

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  adminItems = new BehaviorSubject<Menu[]>(this.ADMINMENU);
}
